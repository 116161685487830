import { createContext, useState } from 'react';
import type { StatsigUser } from 'statsig-react';
import { StatsigProvider } from 'statsig-react';

type StatsigClientContextProps = {
  setSelectedFacilityId: (facilityId: number) => void;
  setUser: (user: StatsigUser) => void;
};

const StatsigClientContext = createContext<StatsigClientContextProps>({
  setSelectedFacilityId() {},
  setUser() {},
});

type Props = {
  children: React.ReactNode;
};

const StatsigClientProvider = ({ children }: Props): JSX.Element => {
  const [user, setUser] = useState<StatsigUser>({});
  const [selectedFacilityId, setSelectedFacilityId] = useState<number>();

  if (!process.env.NEXT_PUBLIC_STATSIG_CLIENT_SECRET_KEY) {
    return <>Missing key</>;
  }

  const statsigUser = {
    ...user,
    custom: {
      facility_id: selectedFacilityId,
    },
  };

  return (
    <StatsigClientContext.Provider
      value={{
        setSelectedFacilityId: setSelectedFacilityId,
        setUser: setUser,
      }}
    >
      <StatsigProvider
        sdkKey={process.env.NEXT_PUBLIC_STATSIG_CLIENT_SECRET_KEY}
        user={statsigUser}
      >
        {children}
      </StatsigProvider>
    </StatsigClientContext.Provider>
  );
};

export { StatsigClientContext, StatsigClientProvider };
