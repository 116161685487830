import { useAuth0 } from '@auth0/auth0-react';
import { useRouter } from 'next/router';
import { createContext, useContext, useEffect } from 'react';
import type Logger from './types';

type Props = {
  children: React.ReactNode;
  logger: Logger;
};

const Context = createContext<{ logger?: any }>({});

const useLogger = () => {
  const values = useContext(Context);
  return values.logger;
};

const useRouteLogging = (logger: Logger) => {
  const router = useRouter();

  useEffect(() => {
    if (!router) return;
    logger.debug('route loaded', {
      path: router.pathname,
      query: router.query,
      route: router.route,
    });
  }, [logger, router]);
};

const useLoginLogging = (logger: Logger) => {
  const { user } = useAuth0();
  useEffect(() => {
    if (!user) return;
    logger.debug('logged in', { user: user.email });
  }, [logger, user]);
};

const LoggerProvider = ({ children, logger }: Props) => {
  useLoginLogging(logger);
  useRouteLogging(logger);
  return <Context.Provider value={{ logger }}>{children}</Context.Provider>;
};

export { LoggerProvider, useLogger };
