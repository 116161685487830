import type { AlertColor } from '@mui/material/Alert';
import Alert from '@mui/material/Alert';
import type { SnackbarCloseReason } from '@mui/material/Snackbar';
import Snackbar from '@mui/material/Snackbar';
import { createContext, useCallback, useState } from 'react';

type ToastContextType = {
  showToast: (type: AlertColor, content: string | React.ReactNode) => void;
};

const ToastContext = createContext<ToastContextType>({
  showToast() {},
});

type Props = {
  children: React.ReactNode;
};

const ToastProvider = ({ children }: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [toastContent, setToastContent] = useState<string | React.ReactNode>(
    '',
  );
  const [toastType, setToastType] = useState<AlertColor | null>(null);

  const handleShowToast = useCallback(
    (type: AlertColor, content: string | React.ReactNode) => {
      setToastType(type);
      setToastContent(content);
      setIsOpen(true);
    },
    [],
  );

  const handleCloseToast = useCallback(
    (_: Event | React.SyntheticEvent, reason?: SnackbarCloseReason) => {
      if (reason === 'clickaway') {
        return;
      }

      setIsOpen(false);
    },
    [],
  );

  return (
    <ToastContext.Provider
      value={{
        showToast: handleShowToast,
      }}
    >
      {children}

      <Snackbar
        open={isOpen}
        autoHideDuration={6000}
        onClose={handleCloseToast}
      >
        <Alert severity={toastType ?? 'info'} onClose={handleCloseToast}>
          {toastContent}
        </Alert>
      </Snackbar>
    </ToastContext.Provider>
  );
};

export { ToastContext, ToastProvider };
