export const commonBaseColors: { [key: string]: string } = {
  white: '#FFFFFF',
  gray50: '#F6F6F6',
  gray100: '#EEEEEE',
  gray200: '#E2E2E2',
  gray300: '#CBCBCB',
  gray400: '#AFAFAF',
  gray500: '#757575',
  gray600: '#545454',
  gray700: '#333333',
  gray800: '#1F1F1F',
  gray900: '#141414',
  black: '#000000',

  platinum50: '#F4FAFB',
  platinum100: '#EBF5F7',
  platinum200: '#CCDFE5',
  platinum300: '#A1BDCA',
  platinum400: '#8EA3AD',
  platinum500: '#6C7C83',
  platinum600: '#556268',
  platinum700: '#394145',
  platinum800: '#142328',

  red50: '#FFEFED',
  red100: '#FED7D2',
  red200: '#F1998E',
  red300: '#E85C4A',
  red400: '#E11900',
  red500: '#AB1300',
  red600: '#870F00',
  red700: '#5A0A00',
  red800: '#D32F2F',

  orange50: '#FFF3EF',
  orange100: '#FFE1D6',
  orange200: '#FABDA5',
  orange300: '#FA9269',
  orange400: '#FF6937',
  orange500: '#C14F29',
  orange600: '#9A3F21',
  orange700: '#672A16',

  internationalOrange700: '#FF4600',

  yellow50: '#FFFBEB',
  yellow100: '#FEF3C7',
  yellow200: '#FDE68A',
  yellow300: '#FCD34D',
  yellow400: '#FBBF24',
  yellow500: '#F59E0B',
  yellow600: '#D97706',
  yellow700: '#B45309',
  yellow800: '#92400E',
  yellow900: '#78350F',

  green50: '#c9ff74',
  green100: '#a6e871',
  green200: '#85d16d',
  green300: '#68ba68',
  green400: '#4da362',
  green500: '#348c5a',
  green600: '#1d7551',
  green700: '#065f46',

  blue50: '#79e9f8',
  blue100: '#46d3f3',
  blue200: '#00bdee',
  blue300: '#00a6e8',
  blue400: '#008ee0',
  blue500: '#0076d5',
  blue600: '#005cc4',
  blue700: '#1e40af',

  teal50: '#e8feff',
  teal100: '#c9e4e5',
  teal200: '#aacacc',
  teal300: '#8cb1b3',
  teal400: '6e989b',
  teal500: '#508184',
  teal600: '32696d',
  teal700: '#0a5357',

  cobalt50: '#EBEDFA',
  cobalt100: '#D2D7F0',
  cobalt200: '#949CE3',
  cobalt300: '#535FCF',
  cobalt400: '#0E1FC1',
  cobalt500: '#0A1899',
  cobalt600: '#081270',
  cobalt700: '#050C4D',

  purple50: '#F3F1F9',
  purple100: '#E3DDF2',
  purple200: '#C1B4E2',
  purple300: '#957FCE',
  purple400: '#7356BF',
  purple500: '#574191',
  purple600: '#453473',
  purple700: '#2E224C',

  brown50: '#F6F0EA',
  brown100: '#EBE0DB',
  brown200: '#D2BBB0',
  brown300: '#B18977',
  brown400: '#99644C',
  brown500: '#744C3A',
  brown600: '#5C3C2E',
  brown700: '#3D281E',

  aquaHaze50: '#f0f4f6',
};
