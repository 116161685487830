import { baseColors } from './base-colors';
import { commonThemePrimitives as themePrimitives } from './common-theme-primitives';

export const commonComponentTheme: { [key: string]: string } = {
  // Background
  backgroundPrimary: themePrimitives.primaryB,
  backgroundSecondary: baseColors.gray800,
  backgroundTertiary: baseColors.gray700,
  backgroundInversePrimary: themePrimitives.primaryA,
  backgroundInverseSecondary: baseColors.gray300,
  backgroundStark: baseColors.black,

  // Content
  contentStark: baseColors.white,
  contentPrimary: themePrimitives.primaryA,
  contentSecondary: baseColors.gray400,
  contentTertiary: baseColors.gray500,
  contentInversePrimary: themePrimitives.primaryB,
  contentInverseSecondary: baseColors.gray600,
  contentInverseTertiary: baseColors.gray500,
  contentPrimaryMuted: baseColors.gray600,

  // Borders
  borderPrimary: baseColors.gray700,
  borderSecondary: baseColors.gray800,

  // Buttons
  buttonPrimaryFill: themePrimitives.primary,
  buttonPrimaryText: themePrimitives.mono600,
  buttonPrimaryHover: themePrimitives.primary100,
  buttonPrimaryHoverText: themePrimitives.mono500,
  buttonPrimaryActive: themePrimitives.primary200,
  buttonPrimarySelectedText: themePrimitives.black,
  buttonPrimarySelectedFill: themePrimitives.primary200,
  buttonPrimarySpinnerForeground: themePrimitives.primary700,
  buttonPrimarySpinnerBackground: themePrimitives.primary300,

  buttonCTAFill: baseColors.internationalOrange700,
  buttonCTAText: baseColors.white,
  buttonCTAHover: baseColors.internationalOrange700,
  buttonCTAHoverText: baseColors.gray500,
  buttonCTAActive: baseColors.internationalOrange700,
  buttonCTASelectedText: themePrimitives.black,
  buttonCTASelectedFill: baseColors.internationalOrange700,

  buttonPrimaryOutlineFill: 'transparent',
  buttonPrimaryOutlineText: baseColors.gray600,
  buttonPrimaryOutlineHover: baseColors.gray400,
  buttonPrimaryOutlineHoverText: baseColors.gray200,
  buttonPrimaryOutlineActive: themePrimitives.primary200,
  buttonPrimaryOutlineSelectedText: themePrimitives.black,
  buttonPrimaryOutlineSelectedFill: themePrimitives.primary200,

  buttonSecondaryFill: themePrimitives.primary700,
  buttonSecondaryText: themePrimitives.primary,
  buttonSecondaryHover: themePrimitives.primary600,
  buttonSecondaryActive: themePrimitives.primary500,
  buttonSecondarySelectedText: themePrimitives.primary,
  buttonSecondarySelectedFill: themePrimitives.primary500,
  buttonSecondarySpinnerForeground: themePrimitives.white,
  buttonSecondarySpinnerBackground: themePrimitives.primary400,

  buttonTertiaryFill: 'transparent',
  buttonTertiaryText: themePrimitives.primary,
  buttonTertiaryHover: themePrimitives.primary700,
  buttonTertiaryActive: themePrimitives.primary600,
  buttonTertiarySelectedText: themePrimitives.primary,
  buttonTertiarySelectedFill: themePrimitives.primary600,
  buttonTertiarySpinnerForeground: themePrimitives.primary50,
  buttonTertiarySpinnerBackground: themePrimitives.primary500,

  buttonMinimalFill: 'transparent',
  buttonMinimalText: themePrimitives.primary,
  buttonMinimalHover: themePrimitives.primary600,
  buttonMinimalActive: themePrimitives.primary600,
  buttonMinimalSelectedText: themePrimitives.primary,
  buttonMinimalSelectedFill: themePrimitives.primary600,
  buttonMinimalSpinnerForeground: themePrimitives.primary50,
  buttonMinimalSpinnerBackground: themePrimitives.primary500,

  buttonDisabledFill: baseColors.gray400,
  buttonDisabledText: baseColors.white,
  buttonDisabledSpinnerForeground: themePrimitives.mono200,
  buttonDisabledSpinnerBackground: themePrimitives.mono400,

  buttonLinkText: themePrimitives.mono200,
  buttonLinkTextHover: themePrimitives.white,

  // Combobox
  comboboxListItemFocus: themePrimitives.mono600,
  comboboxListItemHover: themePrimitives.mono500,

  // Switch
  switchBackground: '#656767',

  // Input
  inputFont: baseColors.gray400,
  inputBorder: themePrimitives.mono600,
  inputBorderActive: themePrimitives.primaryA,
  inputFontActive: themePrimitives.primaryA,
  inputFill: themePrimitives.mono600,
  inputFillActive: themePrimitives.mono500,
  inputFillError: themePrimitives.negative700,
  inputFillDisabled: themePrimitives.mono800,
  inputFillPositive: themePrimitives.positive700,
  inputTextDisabled: themePrimitives.mono500,
  inputBorderError: themePrimitives.negative400,
  inputBorderPositive: themePrimitives.positive400,
  inputEnhancerFill: themePrimitives.mono500,
  inputEnhancerFillDisabled: themePrimitives.mono700,
  inputEnhancerTextDisabled: themePrimitives.mono500,
  inputPlaceholder: themePrimitives.mono300,
  inputPlaceholderDisabled: themePrimitives.mono500,

  // Menu
  menuFill: themePrimitives.mono600,
  menuFillHover: themePrimitives.mono700,
  menuFontDefault: themePrimitives.mono200,
  menuFontDisabled: themePrimitives.mono400,
  menuFontHighlighted: themePrimitives.white,
  menuFontSelected: themePrimitives.white,

  // Table
  tableBackground: 'transparent',
  tableRowHoverBackground: themePrimitives.mono700,

  // Card
  cardPrimaryFill: baseColors.gray700,
  cardPrimaryHeaderColor: themePrimitives.white,
  cardPrimaryContentColor: themePrimitives.mono200,
  cardDividerColor: themePrimitives.primary500,

  // Tags
  outgoingTagBackground: baseColors.blue50,
  outgoingTagContent: baseColors.blue700,
  incomingTagBackground: baseColors.green50,
  incomingTagContent: baseColors.green700,

  // Sidebar
  sidebarBackground: '#202020',
  sidebarSelectedText: themePrimitives.white,
  sidebarUnselectedText: '#C2BFBF',
  sidebarUnselectedBackground: 'transparent',
  sidebarSelectedBackground: '#3F3F3F',

  // Door
  doorShippingBackground: themePrimitives.shipping,
  doorShippingBackgroundHighlight: themePrimitives.shippingHighlight,
  doorShippingColor: themePrimitives.white,
  doorShippingColorBlue: themePrimitives.shippingBlue,
  doorReceivingBackground: themePrimitives.receiving,
  doorReceivingBackgroundHighlight: themePrimitives.receivingHighlight,
  doorReceivingColor: themePrimitives.white,
  doorReceivingColorGreen: themePrimitives.receivingGreen,
  doorNeutralBackground: themePrimitives.mono600,
  doorNeutralColor: themePrimitives.white,
  doorIdle: '#CFCFCF',
  doorForkliftBackground: themePrimitives.forklift,
  doorForkliftBackgroundHighlight: themePrimitives.forkliftHighlight,
  doorForkliftColor: themePrimitives.white,

  // Door dock badge icon
  dockBadgeShipping: themePrimitives.shippingBlue,
  dockBadgeReceiving: themePrimitives.receivingGreen,
  dockBadgeMixed: themePrimitives.mono600,
  dockBadgeUnused: themePrimitives.mono100,
  dockBadgeIdle: '#CFCFCF',

  // Shipping Status Badge
  doorStatusShippingBackground: baseColors.teal50,
  doorStatusShippingColor: baseColors.teal700,
  doorStatusReceivingBackground: baseColors.green50,
  doorStatusReceivingColor: baseColors.green700,
  doorStatusDetentionBackground: baseColors.red50,
  doorStatusDetentionColor: baseColors.red500,

  // Tabs
  tabsBar: baseColors.gray700,
  tabsBarHover: baseColors.gray500,
  tabsBarActive: themePrimitives.white,
  tabsFont: baseColors.gray500,
  tabsFontHover: baseColors.gray200,
  tabsFontActive: themePrimitives.white,

  // VerticalTimeline
  verticalTimelineColor: baseColors.gray600,
  verticalTimelineBackgroundPrimary: baseColors.gray800,
  verticalTimelineBackgroundSecondary: themePrimitives.mutedBackgroundPrimary,

  // horizontalTimeline
  horizontalTimelineBar: themePrimitives.mono400,

  // Overview
  overviewTitleFont: baseColors.gray500,
  metricSecondaryFont: baseColors.gray500,

  // Slider
  sliderBar: themePrimitives.mono600,
  sliderButton: themePrimitives.mono400,

  // Text
  textColor: baseColors.gray550,
};
