import { createContext, useState } from 'react';

type ToastContextType = {
  isDemoMode: boolean;
  toggleDemoMode: () => void;
};

const DemoContext = createContext<ToastContextType>({
  isDemoMode: false,
  toggleDemoMode() {},
});

type Props = {
  children: React.ReactNode;
};

const DemoProvider = ({ children }: Props): JSX.Element => {
  const [isDemoMode, setIsDemoMode] = useState<boolean>(false);

  return (
    <DemoContext.Provider
      value={{
        isDemoMode,
        toggleDemoMode() {
          setIsDemoMode((prev) => !prev);
        },
      }}
    >
      {children}
    </DemoContext.Provider>
  );
};

export { DemoContext, DemoProvider };
