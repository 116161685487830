import { commonBaseColors } from './common-base-colors';

export const lightBaseColors: { [key: string]: string } = {
  ...commonBaseColors,
  gray50: '#F7F7F7',
  gray100: '#F2F2F2',
  gray200: '#EFEFEF',
  gray300: '#DBDADA',
  gray400: '#BBBBBB',
  gray500: '#818181',
  gray550: '#636363',
  gray600: '#3e3e3e',
  gray700: '#202020',
  gray800: '#141414',
  gray900: '#111111',
};
